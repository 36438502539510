import useI18n from '@i18n';
import BaseSanitizer from '@components/base/BaseSanitizer';
import RegisterButton from '@components/pages/crypto/RegisterButton';

import { companyName } from '@config/globalDomain';
import styles from './Header.module.scss';

interface IBlockHeaderProps {
    handleRegistration: () => void;
}

const BlockHeader: React.FC<IBlockHeaderProps> = ({ handleRegistration }) => {
    const { t } = useI18n();
    return (
        <div className={styles.header}>
            <div className={styles.landingContainer}>
                <div className={styles.header_wrapper}>
                    <div className={styles.header_title}>
                        <BaseSanitizer
                            html={t('Monetize your traffic with crypto offers in ePN', {
                                companyName,
                            })}
                            tag="h2"
                        />
                        <div className={styles.header_button}>
                            <RegisterButton handleRegistration={handleRegistration} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlockHeader;
